import { HtmlText } from '@landing-blocks/shared/components/html-text/html-text.js';
import type { FunctionalComponent } from 'preact';

import { BlockTimerBanner1Button } from './__button/block-timer-banner-1__button.js';
import { BlockTimerBanner1Cell } from './__cell/block-timer-banner-1__cell.js';
import { BlockTimerBanner1CloseButton } from './__close-button/block-timer-banner-1__close-button.js';
import { BlockTimerBanner1Countdown } from './__countdown/block-timer-banner-1__countdown.js';
import { BlockTimerBanner1Text } from './__text/block-timer-banner-1__text.js';
import { cnBlockTimerBanner1 } from './block-timer-banner-1.constants.js';

import './block-timer-banner-1.css';

import './_hidden/block-timer-banner-1_hidden.css';

type BlockAttributes = {
    hidden: boolean;

    buttonText: string | undefined;
    buttonLink: string | undefined;
    buttonColor: 'green' | 'orange' | undefined;

    text: string | undefined;

    daysLabel: string;
    hoursLabel: string;
    minsLabel: string;
    secsLabel: string;

    days: number;
    hours: number;
    mins: number;
    secs: number;

    closeButtonLabel: string;
    onCloseButtonClick: VoidFunction;
};

export interface BlockTimerBanner1Props extends BlockAttributes {}

export const BlockTimerBanner1: FunctionalComponent<BlockTimerBanner1Props> = ({
    buttonText,
    buttonLink,
    text,
    buttonColor,
    hidden,
    days,
    hours,
    mins,
    secs,
    daysLabel,
    hoursLabel,
    minsLabel,
    secsLabel,
    closeButtonLabel,
    onCloseButtonClick,
}) => (
    <div className={cnBlockTimerBanner1({ hidden })}>
        <BlockTimerBanner1Text>
            <HtmlText html={text} />
        </BlockTimerBanner1Text>
        <BlockTimerBanner1Countdown>
            <BlockTimerBanner1Cell value={days} label={daysLabel} />
            <BlockTimerBanner1Cell value={hours} label={hoursLabel} />
            <BlockTimerBanner1Cell value={mins} label={minsLabel} />
            <BlockTimerBanner1Cell value={secs} label={secsLabel} />
        </BlockTimerBanner1Countdown>
        <BlockTimerBanner1Button link={buttonLink} color={buttonColor}>
            {buttonText}
        </BlockTimerBanner1Button>
        <BlockTimerBanner1CloseButton onClick={onCloseButtonClick} title={closeButtonLabel} />
    </div>
);
